<template>
  <el-autocomplete
      ref="autocomplete"
      v-bind="[$attrs]"
      popper-class="site-autocomplete"
      value-key="area_name"
      :fetch-suggestions="querySearch"
      placeholder="中文 / 拼音 / 简拼"
      @select="handleSelect"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      :trigger-on-focus="true"
      v-loading="loading"
      :class="type">
    <template slot="prepend">{{ type === TYPE_LEAVE ? '出发城市' : '到达城市' }}</template>
    <el-popover
        ref="pop"
        slot="suffix"
        placement="bottom-end"
        width="450"
        trigger="click"
        @show="handlePopShow"
        v-if="history || restaurantGroup">
      <div class="city-pop">
        <div class="city-pop-history" v-if="history">
          <div class="city-pop-title">搜索历史</div>
          <ul class="city-pop-ul">
            <li v-for="item in history" :key="item.area_id" @click="handlePopSelect(item)">
              {{ item.area_name }}
            </li>
          </ul>
        </div>
        <div class="city-pop-group" v-if="restaurants.length">
          <el-tabs>
            <el-tab-pane v-for="(group1, index) of Object.keys(restaurantGroup)" :key="index" :label="group1">
              <ul>
                <li class="city-pop-group-item" v-for="(group2, index) of Object.keys(restaurantGroup[group1])" :key="index">
                  <span class="city-pop-title">{{ group2 }}</span>
                  <ul class="city-pop-ul">
                    <li v-for="item in restaurantGroup[group1][group2]" :key="item.area_id" @click="handlePopSelect(item)">
                      {{ item.area_name }}
                    </li>
                  </ul>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <i slot="reference" class="el-icon-aim el-input__icon"></i>
    </el-popover>
    <template slot-scope="{ item }">
      <span class="name">{{ item.area_name }}</span>
      <span class="p-name">{{ item.p_area_name }}</span>
    </template>
  </el-autocomplete>
</template>

<script>
import {getAreaInfo} from "@/utils/common";
import {getAreaInfo as getAreaInfoApi} from '@/api/cj-customer'

export default {
  name: "city-select-input",
  props: ['type', 'leave'],
  data() {
    return {
      TYPE_LEAVE: 'leave',
      TYPE_ARRIVE: 'arrive',
      groupTags: ['ABCDE', 'FGHJ', 'KLMNP', 'QRST', 'WXYZ'],
      restaurants: [],
      restaurantGroup: null,
      history: null,
      loading: false,
    }
  },
  computed: {
    historyKey() {
      return `${this.type}-city-history`
    }
  },
  watch: {
    leave(val) {
      this.setRestaurants(val.area_code)
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    handleSelect(item) {
      this.cacheHistory(item)
      this.$emit('select', item);
    },
    handlePopSelect(item) {
      this.$refs.pop.doClose()
      this.cacheHistory(item)
      this.$emit('popSelect', item);
    },
    handlePopShow() {
      this.$refs.autocomplete.close()
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.area_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0) ||
            (restaurant.area_short.toLowerCase().indexOf(queryString.toLowerCase()) === 0) ||
            (restaurant.area_full.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    setRestaurants(areaCode) {
      switch (this.type) {
        case this.TYPE_LEAVE:
          this.loading = true;
          getAreaInfo().then(data => {
            this.loading = false
            this.restaurants = data
            this.groupRestaurants()
          }).catch(() => {
            this.loading = false
            // this.$message.error(e.message)
            // this.restaurants = []
            // this.restaurantGroup = null
          })
          break;
        case this.TYPE_ARRIVE:
          this.loading = true;
          getAreaInfoApi(areaCode).then(data => {
            this.loading = false
            this.restaurants = data
            this.groupRestaurants()
          }).catch(() => {
            this.loading = false
            // this.$message.error(e.message)
            // this.restaurants = []
            // this.restaurantGroup = null
          })
          break;
      }
    },
    cacheHistory(item) {
      let caches
      caches = JSON.parse(localStorage.getItem(this.historyKey))
      if (!caches) caches = []
      for (const value of caches) {
        if (value.area_code === item.area_code) {
          caches.splice(caches.indexOf(value), 1)
          break
        }
      }
      caches.unshift(item)
      caches.splice(10)
      localStorage.setItem(this.historyKey, JSON.stringify(caches))
      this.history = caches;
    },
    groupRestaurants() {
      this.restaurantGroup = {}
      this.restaurants.forEach(item => {
        this.groupTags.forEach(tag => {
          if (tag.includes(item.pinyin)) {
            const group1 = this.restaurantGroup[tag] ? this.restaurantGroup[tag] : {}
            this.restaurantGroup[tag] = group1
            const group2 = group1[item.pinyin] ? group1[item.pinyin] : []
            group1[item.pinyin] = group2
            group2.push(item)
          }
        })
      })
    }
  },
  mounted() {
    if (this.type === this.TYPE_LEAVE) this.setRestaurants()
    this.history = JSON.parse(localStorage.getItem(this.historyKey))
  }
}
</script>

<style lang="scss">
.el-autocomplete {
  width: 100%;

  .el-input-group__prepend {
    color: #ffffff;
  }

  &.leave .el-input-group__prepend {
    background-color: $--color-primary;
    border-color: $--color-primary;
  }

  &.arrive .el-input-group__prepend {
    background-color: $--color-secondary;
    border-color: $--color-secondary;
  }

  .el-input__suffix {
    cursor: pointer;

    &:hover, &:active {
      color: $--color-text-primary;
    }
  }
}

.site-autocomplete {
  li {
    display: flex;
    justify-content: space-between;

    .name {
      color: $--color-text-regular;
    }

    .p-name {
      font-size: smaller;
      color: $--color-text-placeholder;
    }

    .highlighted .p-name {
      color: #ddd;
    }
  }
}

.city-pop {
  .city-pop-title {
    color: $--color-primary;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .city-pop-ul {
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 0 15px 0 0;
      cursor: pointer;

      &:hover, &:active {
        color: $--color-primary;
      }
    }
  }

  .city-pop-history {
    margin: 0 0 5px 0;
  }

  .city-pop-group {
    .el-tab-pane {
      max-height: 300px;
      overflow-y: auto;
    }

    .city-pop-group-item {
      display: flex;

      .city-pop-title {
        margin: 0 20px 0 10px;
      }

      .city-pop-ul {
        li {
          padding: 0 15px 15px 0;
        }
      }
    }
  }
}
</style>
import {send} from "@/api/common";

const path = `/interCity/customer`

async function getAreaInfo(leaveCode) {
    const req = {
        "optid": "get_area_info",
        "terminal": "1",
        "version": "3",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "start_area_code": leaveCode
        }
    }

    return send(path, req)
}

async function getClassesList(params) {
    const req = {
        "optid": "get_classes_list",
        "terminal": "1",
        "version": "4",
        "common_param": {
            "page_index": "1",
            "page_size": "999",
            "is_paging": "1",
            "is_couting": "0"
        },
        "business_param": {
            "start_area_id": params.leave,   //出发地区域id(必填)
            "arrive_area_id": params.arrive,   //到达地区域id(必填)
            "start_date": params.date,    //出发日期(必填) yyyy-MM-dd
            "get_on_site_id": params.leaveSite,    //上车站点id(非必填)
            "get_down_site_id": params.arriveSite,    //下车站点id(非必填)
            "send_time": params.time,    //发车时间(非必填)  格式：HH:mm-HH:mm
            "line_sub_id": null    //子线路id(非必填)
        }
    }

    return send(path, req)
}

async function getClassesFilterCondition(params) {
    const req = {
        "optid": "get_classes_filter_condition",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "start_area_id": params.leave,   //出发地区域id(必填)
            "arrive_area_id": params.arrive,   //到达地区域id(必填)
            "start_date": params.date    //出发日期(必填) yyyy-MM-dd
        }
    }

    return send(path, req)
}

async function lineCharterInfo(params) {
    const req = {
        "optid": "line_charter_info",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "start_area_id": params.leave,   //出发地区域id(必填)
            "arrive_area_id": params.arrive   //到达地区域id(必填)
        }
    }

    return send(path, req)
}

async function getRefundRule(classesId) {
    const req = {
        "optid": "query_refund_rule",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "classes_id": classesId    //班次id(必填)
        }
    }

    return send(path, req)
}

async function getClassesInfo(classesId) {
    const req = {
        "optid": "get_classes_info",
        "terminal": "1",
        "version": "3",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "classes_id": classesId,   //班次id(必填)
            "purpose": null,    //用途(非必填)  1：预订  2：班次申请  3：即时下单
            "dt_id": null    //任务id(非必填) 传此参数会返回司机与车辆信息
        }
    }

    return send(path, req)
}

async function canJoinActivity(params) {
    const req = {
        "optid": "can_join_activity",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "1",
            "is_couting": "0"
        },
        "business_param": {
            "user_id": params.userId,   //用户id(必填)
            "classes_id": params.classesId,    //班次id(必填)
            "rebook_order_id": null,    //改签的原订单id(非必填)
        }
    }

    return send(path, req)
}

async function createOrder(params) {
    const req = {
        "optid": "create_order",
        "terminal": "1",
        "version": "3",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "user_id": params.userId,   //用户id(必填)
            "classes_id": params.classesId,    //班次id(必填)
            "contacts": params.contacts.name,    //联系人(必填)
            "contacts_phone": params.contacts.phone,    //联系人电话(必填)
            "order_amount": params.amount,    //订单总金额(必填)
            "coupon_id": null,    //优惠券id(非必填)
            "coupon_deduction_amount": null,    //优惠券抵扣金额(非必填)
            "activity_id": params.activityId,    //优惠活动id(非必填)
            "adult_num": params.adultNum,    //成人数量(必填)
            "adult_price": params.adultPrice,    //成人单价(必填)
            "children_num": params.childNum,    //儿童数量(必填)
            "children_price": params.childPrice,    //儿童单价(必填)
            "baby_num": params.babyNum,    //携童数量(必填)
            "get_on_site_id": params.leaveSite.id,    //上车站点id(必填)
            "get_down_site_id": params.arriveSite.id,    //下车站点id(必填)
            "call_address": null,    //叫车地址(必填)
            "call_longitude": params.call.lon,    //叫车点经度(必填)
            "call_latitude": params.call.lat,    //叫车点纬度(必填)
            "get_on_address": params.leaveSite.name,    //上车地址(必填)
            "get_on_longitude": params.leaveSite.lon,    //上车点经度(必填)
            "get_on_latitude": params.leaveSite.lat,    //上车点纬度(必填)
            "get_down_address": params.arriveSite.name,    //下车地址(必填)
            "get_down_longitude": params.arriveSite.lon,    //下车点经度(必填)
            "get_down_latitude": params.arriveSite.lat,    //下车点纬度(必填)
            "booking_go_time": params.time,    //预约出行时间段(非必填)    滚动班必填 格式：HH:mm-HH:mm
            "source_platform": 8,    //下单平台(必填) 1、APP 2、后台 3、微信公众号  4、分销商  5、小程序 8、官网
            "openid": null,    //openid(非必填) 微信公众号与小程序必填
            "conduct_id": null,    //推广人(非必填)
            "source_detail": null,    //来源商家编号(非必填)  如来源是外部公众号跳转，则存储商家公众号标识
            "passenger_list": params.passengers,    //乘车人明细(非必填) 实名认证时传递
            "rebook_order_id": null,    //改签的原订单id(非必填)
            "seat_no": params.seatNo,    //座位号(非必填) 多个逗号分隔
            "platform": null,    //平台标识(非必填)
            "dt_id": null,    //任务id(非必填)
            "travel_mode": 1    //出行模式(必填) 1：预约出行  2：即时出行
        }
    }

    return send(path, req)
}

async function orderPay(params) {
    const req = {
        "optid": "order_pay",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "order_id": params.orderId,   //订单id(必填)
            "openid": "", //用户openid(非必填) 微信公众号支付需要
            "pay_type": params.payType,   //支付方式(非必填) 8：支付宝   9:微信
            "ip": '127.0.0.1',   //终端ip地址(非必填)
            "is_minipg": null, // 1 小程序支付
            "pay_mode": 2    //支付模式 1：唤醒支付  2：扫码支付
        }
    }

    return send(path, req)
}

async function getOrderList(params) {
    const req = {
        "optid": "order_list",
        "terminal": "1",
        "version": "4",
        "common_param": {
            "page_index": params.pageIndex ? params.pageIndex : 0,
            "page_size": 10,
            "is_paging": "1",
            "is_couting": "0"
        },
        "business_param": {
            "user_id": params.userId,   //用户id(必填)
            "span": params.span    //跨度(必填) 1:3个月内  2:3个月外
        }
    }

    return send(path, req)
}

async function getOrderInfo(orderId) {
    const req = {
        "optid": "get_order_info",
        "terminal": "1",
        "version": "3",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "order_id": orderId   //订单id(必填)
        }
    }

    return send(path, req)
}

async function orderCancel(orderId) {
    const req = {
        "optid": "order_cancel",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "order_id": orderId   //订单id(必填)
        }
    }

    return send(path, req)
}

async function orderDel(orderId) {
    const req = {
        "optid": "order_del",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "order_id": orderId   //订单id(必填)
        }
    }

    return send(path, req)
}

export {
    getAreaInfo,
    getClassesList,
    getClassesFilterCondition,
    lineCharterInfo,
    getRefundRule,
    getClassesInfo,
    canJoinActivity,
    createOrder,
    orderPay,
    getOrderList,
    getOrderInfo,
    orderCancel,
    orderDel,
}
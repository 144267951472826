<template>
  <div class="copyright">
    <p>©&nbsp;四川富临蜜蜂出行科技有限公司</p>
    <p>蜀ICP备16030281号-2 | 川公网安备 51010702001146号</p>
    <p>增值电信业务经营许可证 川B2-20210736</p>
  </div>
</template>

<script>
export default {
  name: "footer-small"
}
</script>

<style scoped>
  .copyright {
    padding: 2rem 0 .2rem 0;
    width: 100%;
    font-size: .24rem;
  }
  .copyright p {
    text-align: center;
    color: #fff;
  }
</style>
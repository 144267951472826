<template>
  <div>
    <el-page-header @back="onBack" :title="pageTitle">
      <template slot="content">
        <div>{{ order.start_area_name }}</div>
        <div class="classes-date">
          <div class="date-week">
            <span class="date">{{ order.date.format('YYYY-MM-DD') }}</span>
            <span class="week">{{ order.date.isSame(now) ? '今天' : order.date.format('ddd') }}</span>
          </div>
        </div>
        <div>{{ order.arrive_area_name }}</div>
      </template>
    </el-page-header>
    <div class="input-container text-primary">
      <div class="input-content classes-leave">
        <span class="input-label"><i class="input-label-leave"></i>上车地点</span>
        <span class="content-name">{{ order.get_on_address }}</span>
      </div>
      <p class="content-tips"></p>
      <div class="input-content classes-arrive">
        <span class="input-label"><i class="input-label-arrive"></i>下车地点</span>
        <span class="content-name">{{ order.get_down_address }}</span>
      </div>
      <p class="content-tips"></p>
      <div class="input-content">
        <span class="input-label"><i class="input-label-time"></i>出行时间</span>
        <span class="content-name">{{ order.send_time }} {{ order.send_time_suffix }}</span>
      </div>
      <p class="content-tips"></p>
      <div class="input-content" style="display: flex; align-items: flex-start">
        <span class="input-label"><i class="input-label-space"></i>应付金额</span>
        <span class="content-name color-secondary">¥ <span style="font-size: xxx-large">{{
            toThousands(order.actual_amount)
          }}</span></span>
      </div>
    </div>
    <el-divider>支付</el-divider>
    <el-alert type="error" :closable="false" style="margin-bottom: 20px" center v-if="!payFinish">
      <template slot="title" v-if="expired">支付超时，订单已自动取消，请重新下单</template>
      <template slot="title" v-else>请您在 <span>{{ overtimeFormat }}</span> 内完成在线支付，过时订单会自动取消</template>
    </el-alert>
    <el-button icon="el-icon-wechat-pay" :disabled="expired" @click="onClickWechatPay">微信支付</el-button>
    <el-button icon="el-icon-alipay" :disabled="expired" @click="onClickAlipay">支付宝支付</el-button>
    <div>
      <p style="font-weight: 500; margin: 40px 0 10px">您还可以选择以下方式购票：</p>
      <p>1、微信搜索并关注“天府行”公众号</p>
      <p>2、下载“天府行”APP预定</p>
      <p>3、电话联系客服，预定电话：<a href="tel:96579">96579</a></p>
    </div>
    <el-dialog :title="payDialog.title" :visible.sync="payDialog.visible" width="400px">
      <div style="display: flex; justify-content: center; align-items: baseline">
        <span style=" font-weight: 400">支付金额：</span>
        <span class="color-secondary" style=" font-weight: 700">¥ <span style="font-size: x-large">{{
            toThousands(order.actual_amount)
          }}</span></span>
      </div>
      <div class="color-secondary" style="display: flex; justify-content: center; font-size: 12px">
        <span>支付倒计时：</span>
        <span>{{ overtimeFormat }}</span>
      </div>
      <div style="display: flex; justify-content: center; margin: 20px 0">
        <vue-qr :logo-src="qr.logo" :text="qr.text" :size="500" style="width: 162px; height: 162px"></vue-qr>
      </div>
      <div style="display: flex; align-items: center; justify-content: center">
        <span style="margin-right: 10px"><img width="26" height="26" src="../assets/icon/qrcode-scan.png"></span>
        <span v-html="payDialog.scanTips" style="font-size: 12px"></span>
      </div>
    </el-dialog>
    <el-dialog title="支付成功" :visible.sync="payFinish" width="400px" @closed="onPayFinish">
      <div class="color-secondary" style="display: flex; justify-content: center; font-size: 12px">
        <span>温馨提示：关注天府行公众号或下载安装天府行APP，可进行退票，改签，开电子发票，查看车辆位置等更多操作！</span>
      </div>
      <div style="display: flex; justify-content: center; margin: 20px 0">
        <img height="152" width="152" src="../assets/img/wechat-qrcode.png">
      </div>
      <div style="display: flex; justify-content: center">
        <span style="font-size: 12px">微信扫描上方二维码关注天府行公众号</span>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-button type="primary" style="width: 320px" @click="payFinish = false">返回我的订单</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Element from "element-ui";
import moment from "moment";
import {toThousands as toThousandsCommon} from "@/utils/common";
import {getServerTimestamp} from "@/api/cj-common";
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
import {getOrderInfo, orderPay} from "@/api/cj-customer";

Vue.use(Element)
moment.locale('zh-cn')

export default {
  name: "pay",
  props: ['step', 'preStep', 'order', 'pageTitle'],
  components: {VueQr},
  data() {
    return {
      now: moment(),
      expired: false,
      overtime: null,
      overtimeFormat: '...',
      overtimeTimerID: null,
      payFinish: false,
      payFinishTimerID: null,
      payDialog: {
        title: '',
        visible: false,
        scanTips: '',
      },
      qr: {},
    }
  },
  methods: {
    onBack() {
      this.$emit('update:step', this.preStep)
    },
    overtimeTimer(now) {
      const overtime = moment.duration(moment(this.order.pay_over_time, 'YYYY-MM-DD HH:mm:ss').diff(now))
      if (overtime.asMilliseconds() <= 0) {
        this.expired = true
        this.payDialog.visible = false
        clearInterval(this.payFinishTimerID)
      } else {
        this.expired = false
        this.overtimeFormat = `${parseInt(overtime.asMinutes())}分${overtime.seconds()}秒`
        this.overtimeTimerID = setTimeout(() => this.overtimeTimer(now.add(1, 'seconds')), 1000)
      }
    },
    payFinishTimer() {
      getOrderInfo(this.order.order_id).then(data => {
        if (data[0].status >= 2) {
          clearTimeout(this.overtimeTimerID)
          this.payDialog.visible = false
          this.payFinish = true
          clearInterval(this.payFinishTimerID)
        }
      }).catch(e => {
        console.error(e.message)
      })
    },
    toThousands(num) {
      return toThousandsCommon(num)
    },
    onClickWechatPay() {
      this.openPay(9)
    },
    onClickAlipay() {
      this.openPay(8)
    },
    openPay(type) {
      const params = {
        orderId: this.order.order_id,
        payType: type,
      }
      switch (type) {
        case 8:
          this.qr.logo = '/img/alipay.png'
          this.payDialog.title = '支付宝支付'
          this.payDialog.scanTips = '打开手机支付宝<br>扫一扫二维码'
          break
        case 9:
          this.qr.logo = '/img/wechat-pay.png'
          this.payDialog.title = '微信支付'
          this.payDialog.scanTips = '打开手机微信<br>扫一扫二维码'
          break
      }
      const loading = this.$loading()
      orderPay(params).then(data => {
        loading.close()
        this.qr.text = data[0].detail
        this.payDialog.visible = true
        this.payFinishTimerID = setInterval(() => this.payFinishTimer(), 5000)
      }).catch(e => {
        loading.close()
        console.error(e.message)
        this.$message.error('支付异常，请稍后再试')
      })
    },
    onPayFinish() {
      this.$emit('payFinish')
    }
  },
  mounted() {
    window.scroll(0, 0)
    getServerTimestamp().then(data => {
      this.overtimeTimer(moment(data[0].server_timestamp))
    })
  }
}
</script>

<style lang="scss">
</style>
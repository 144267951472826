<template>
  <footer>
    <div class="logo"><img src="images/large/icon/logo-footer.svg"></div>
    <div class="company-copyright-qr">
      <div class="company">
        <p><img src="images/large/icon/phone.png"><span><a href="tel:96579">96579</a></span></p>
        <p><img src="images/large/icon/email.png"><span><a href="mailto:bd@tfxing.com">bd@tfxing.com</a></span></p>
        <p><img src="images/large/icon/address.png"><span>成都市青羊区腾飞大道189号</span></p>
        <p class="links"><img src="images/small/icon/links.png"><a href="http://www.china-yongfeng.com/" target="_blank">永锋集团</a>&nbsp;&nbsp;<a
            href="http://www.scflyy.cn/" target="_blank">富临运业集团</a></p>
      </div>
      <div class="copyright-qr">
        <div class="copyright">
          <div>©&nbsp;四川富临蜜蜂出行科技有限公司</div>
          <div>
            <a target="_blank"
               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702001146&amp;token=3849d572-389b-46ed-9ea8-aa9c0da1dfbb">
              <img src="images/beianbgs.png" style="width: 12px;">&nbsp;川公网安备 51010702001146号</a>
          </div>
          <div><a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备16030281号-2</a></div>
          <div>增值电信业务经营许可证&nbsp;川B2-20210736</div>
        </div>
        <div class="qr">
          <img src="images/large/qr.svg">
          <span>微信扫码关注</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
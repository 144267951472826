import {send} from "@/api/common";

const path = `/interCity/common`

async function getServerTimestamp() {
    const req = {
        "optid": "get_server_timestamp",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
        }
    }

    return send(path, req)
}

export {getServerTimestamp}
<template>
  <div>
    <div class="nav-logo">
      <img class="logo" @click="goHome" src="images/small/icon/logo.png">
      <img class="menu-icon" src="images/small/icon/menu-icon.png">
    </div>
    <div class="nav-list display-none">
      <div class="nav-logo-close">
        <img class="close" src="images/small/icon/menu-close.png">
      </div>
      <ul style="display: none;">
        <li><a :class="active === 'index' ? 'active' : ''" href="/">首页</a></li>
        <li><a :class="active === 'product' ? 'active' : ''" href="product.html">产品</a></li>
        <li><a :class="active === 'news' ? 'active' : ''" href="news.html">动态</a></li>
        <li><a :class="active === 'about' ? 'active' : ''" href="about.html">关于</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-nav-small",
  props: ['active'],
  methods: {
    goHome() {
      location.href = '../'
    }
  }
}
</script>

<style scoped>

</style>
import axios from 'axios'
import des3 from "@/utils/des3";

const isEncode = process.env.VUE_APP_API_ENCODE === 'true'

function encode(req) {
    return isEncode ? des3.encrypt(JSON.stringify(req)) : req
}

function decode(res) {
    return isEncode ? JSON.parse(des3.decrypt(res)) : res
}

export async function send(path, req) {
    let res
    try {
        res = (await axios.post(`${process.env.VUE_APP_API_ADDR}${path}${!isEncode ? '?enc=0' : ''}`, encode(req), {
            headers: {'content-type': 'application/json;charset=utf-8'}
        })).data
        res = decode(res)
    } catch (e) {
        throw new Error('网络异常，请稍后再试')
    }
    if (res.result_state) {
        throw new Error(res.result_msg)
    }

    return res.result
}

// 高德不再提供此接口
// export async function getLocation(ip) {
//     let res
//     try {
//         res = (await axios.post(`https://restapi.amap.com/v5/ip?key=${process.env.VUE_APP_AMAP_SERVICE_KEY}&type=4&ip=${ip}`)).data
//     } catch (e) {
//         throw new Error('网络异常，请稍后再试')
//     }
//     if (res.status === '0') {
//         throw new Error(res.info)
//     }
//     return res
// }


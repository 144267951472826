import {send} from "@/api/common";

const path = `/userCenter`

async function getCommonPage() {
    const req = {
        "optid": "get_common_page",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {}
    }

    return send(path, req)
}

async function getCaptchaConfig() {
    const req = {
        "optid": "captcha_config",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "client_type": "1", //终端类型(必填) 1：乘客端 2：司机端
            "scene": "ic_login_h5"    //场景(必填) ic_login_h5：登录
        }
    }

    return send(path, req)
}

async function login(params) {
    const req = {
        "optid": "user_login",
        "terminal": "1",
        "version": "2",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "login_type": params.type,    //登录类型(必填) 1验证码登录 2密码登录  3微信登录
            "phone": params.phone,    //手机号(必填)
            "password": params.password,    //密码(非必填) 默认登录时使用
            "verification_code": params.captcha,    //短信验证码(非必填)
            "openid": null,    //微信openid(非必填)
            "nickname": null,    //昵称(非必填)
            "sex": null,    //性别 1男  0女(非必填)
            "conduct_source": null,    //推广人来源(非必填) 1 票务  2旅游  3城际  99：乘客推广
            "conduct_id": null,    //推广人(非必填)
            "source": "8",    //终端来源(必填) 1、IOS  2、ANDROID  3、微信公众号  4、微信小程序  5、后台   6、渠道 7、H5 8、官网
            "source_detail": 'tfxingpt',    //商家来源 传递标识分类 天府行：tfxingpt
            "area_code": params.code,    //区域码(非必填) 行政区划，未定位不传
            "imsi": null,   //IMSI号(非必填)
            "imei": null,   //IMEI号(非必填)
            "mac": null,   //移动终端硬件标识/MAC(非必填)
            "longitude": params.lon,   //经度(非必填)
            "latitude": params.lat   //纬度(非必填)
        }
    }

    return send(path, req)
}

async function getContacts(userId) {
    const req = {
        "optid": "get_contacts_list",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "user_id": userId    //用户id(必填)
        }
    }

    return send(path, req)
}

async function delContacts(id) {
    const req = {
        "optid": "delete_contacts_info",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "id": id    //联系人id(必填)
        }
    }

    return send(path, req)
}

async function addContacts(params) {
    const req = {
        "optid": "add_contacts_info",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "user_id": params.userId,    //用户id(必填)
            "name": params.name,   //联系人姓名(必填)
            "sex": null,   //性别(非必填) 0：女 1：男
            "type": params.type,   //类型(必填) 0：儿童 1：成人
            "nation": null,   //民族(非必填) 数据字典
            "id_type": params.idType,   //证件类型(非必填) 数据字典
            "id_card": params.idCard,   //证件号(非必填)
            "birthday": null,   //出生日期(非必填)
            "phone": params.phone,   //联系人手机号(非必填)
            "nationality": null   //国籍数据字典(非必填)
        }
    }

    return send(path, req)
}

async function editContacts(params) {
    const req = {
        "optid": "edit_contacts_info",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "id": params.id,    //用户id(必填)
            "name": params.name,   //联系人姓名(必填)
            "sex": null,   //性别(非必填) 0：女 1：男
            "type": params.type,   //类型(必填) 0：儿童 1：成人
            "nation": null,   //民族(非必填) 数据字典
            "id_type": params.idType,   //证件类型(非必填) 数据字典
            "id_card": params.idCard,   //证件号(非必填)
            "birthday": null,   //出生日期(非必填)
            "phone": params.phone,   //联系人手机号(非必填)
            "nationality": null   //国籍数据字典(非必填)
        }
    }

    return send(path, req)
}

async function wechatGetConfig(url) {
    const req = {
        "optid": "wechat_get_config",
        "terminal": "1",
        "version": "1",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "url": url
        }
    }

    return send(path, req)
}

export {getCommonPage, getCaptchaConfig, login, getContacts, delContacts, addContacts, editContacts, wechatGetConfig}
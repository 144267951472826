import $ from 'jquery'
import {getAreaInfo as getAreaInfoApi} from "@/api/cj-customer";
import AMapLoader from "@amap/amap-jsapi-loader";

/*
 * android禁止微信浏览器调整字体大小
 *  这种方法会导致网页延迟大约1S
 */
function handleFontSize() {
    // 设置网页字体为默认大小
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke('setFontSizeCallback', {
        'fontSize': 0
    });
    // 重写设置网页字体大小的事件
    // eslint-disable-next-line no-undef
    WeixinJSBridge.on('menu:setfont', function () {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke('setFontSizeCallback', {
            'fontSize': 0
        });
    });
}

// eslint-disable-next-line no-undef
if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
} else {
    if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
    }
}

// 获取url上面参数值
function theRequest(url) {
    const theRequest = {};
    let strs = "";
    if (url) {
        strs = url.split("&");
        for (var k = 0; k < strs.length; k++) {
            theRequest[strs[k].split("=")[0]] = decodeURIComponent(strs[k].split("=")[1]);
        }
    }
    return theRequest;
}

//判读是否是微信浏览器
function isWeiXin() {
    const ua = window.navigator.userAgent.toLowerCase();
    // console.log(ua);//mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

function getDomain() {
    const curWwwPath = window.document.location.href;
    const pathName = window.document.location.pathname;
    return curWwwPath.substring(0, curWwwPath.indexOf(pathName));
}

const AlertTip = {
    "close": function () {
        $("#markDiv, #layerDiv").remove();
    },
    "mark": "<div id='markDiv'></div>",
    "loading": function (_loadMsg) {
        const _load = "<div id='layerDiv'>" +
            "<div class='layerLoad'>" +
            "<div class='loader-inner ball-pulse'>" +
            "<div></div>" +
            "<div></div>" +
            "<div></div>" +
            "</div>" +
            "<button class='loadingMsg'>" + _loadMsg + "</button>" +
            "</div>" +
            "</div>";
        $("body").append(AlertTip.mark + _load);
    },
    "alert": function (_msg) {
        let _str = "";
        if ($("#layerDiv").length == 0) {
            _str = "<div id='layerDiv'>" +
                "<div class='close_btn'>×</div>" +
                "<div class='layerTitle'>温馨提示</div>" +
                "<div class='layerCenter'>" +
                "<div class='layerMsg'>" + _msg + "</div>" +
                "</div>";
            _str += "<div class='layerBottom'>" +
                "<button class='closeAlert'>我知道了</button>" +
                "</div>";
            _str += "</div>";
            $("body").append(AlertTip.mark + _str);
        }
        $(".closeAlert, .close_btn, #markDiv").on("click", function () {
            AlertTip.close();
        });
    },
    "alertUrl": function (url) {
        let _str = "";
        if ($("#layerDiv").length == 0) {
            _str = "<div id='layerDiv' class='loadUrl'>" +
                "<div class='layerClose'>x</div>" +
                "<div class='layerCenter'></div>";
            "</div>";
            $("body").append(AlertTip.mark + _str);
            $("#layerDiv").css({"padding": "10px", "height": "75%"});
            $(".layerCenter").css({"height": "95%", "overflow-y": "scroll"});
            $(".layerCenter").load(url);
        } else {
            $(".layerLoad").remove();
            _str = "<div class='layerClose'>x</div>" +
                "<div class='layerCenter'></div>";
            "</div>";
            $("#layerDiv").append(_str);
            $("#layerDiv").css({"padding": "10px", "height": "75%"});
            $(".layerCenter").css({"height": "95%", "overflow-y": "scroll"});
            $("#layerCenter").load(url);

        }
        $(".layerClose, #markDiv").on("click", function () {
            AlertTip.close();
        });
    }
};

async function getAreaInfo() {
    if (!sessionStorage.getItem('area-info')) {
        sessionStorage.setItem('area-info', JSON.stringify(await getAreaInfoApi()))
    }
    return JSON.parse(sessionStorage.getItem('area-info'))
}

let AMap = null

async function getAMap() {
    if (!AMap) {
        AMap = await AMapLoader.load({
            "key": process.env.VUE_APP_AMAP_KEY,
        })
    }
    return AMap
}

function getUser() {
    return JSON.parse(sessionStorage.getItem('user-info'))
}

/**
 * 千位符格式化，保留两位小数，带货币符号
 *
 * @param num
 * @returns {string}
 */
function toThousandsCurrency(num) {
    return new Number(num || 0).toLocaleString(undefined, {style: 'currency', currency: 'CNY'});
}

/**
 * 千位符格式化，保留两位小数
 *
 * @param num
 * @returns {string}
 */
function toThousands(num) {
    return new Number(num || 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export {handleFontSize, isWeiXin, theRequest, getDomain, AlertTip, getAreaInfo, getAMap, getUser, toThousands, toThousandsCurrency}
<template>
  <div class="choose-seats-app">
    <el-divider>选座</el-divider>
    <div class="choose-seats-container">
      <div class="choose-seats">
        <div class="choose-seats-head">
          <div class="choose-seats-hr"></div>
          <div class="choose-seats-h"></div>
          <div class="choose-seats-hl"></div>
        </div>
        <div class="choose-seats-body">
          <div class="choose-seats-right">
            <div class="choose-seats-rh"></div>
            <div class="choose-seats-r"></div>
          </div>
          <div class="choose-seats-item">
            <div class="choose-seats-item-y" v-for="x in seatInfo.rage.x" :key="x">
              <div v-for="y in seatInfo.rage.y" :key="y">
                <i :class="{
                      'seat-driver': !thisSeatInfos[`${x}-${y}`].type,
                      'seat-sell': thisSeatInfos[`${x}-${y}`].type && (onlyShow || thisSeatInfos[`${x}-${y}`].sell_status === 1),
                      'seat-sold': thisSeatInfos[`${x}-${y}`].type && (!onlyShow && thisSeatInfos[`${x}-${y}`].sell_status === 2),
                      'seat-nosell': thisSeatInfos[`${x}-${y}`].sell_status === 0,
                      'seat-selected': thisSeatInfos[`${x}-${y}`].selected
                    }" @click="onlyShow ? '' : onClickSeat(thisSeatInfos[`${x}-${y}`])"></i>
              </div>
            </div>
          </div>
          <div class="choose-seats-left">
            <div class="choose-seats-lh"></div>
            <div class="choose-seats-l"></div>
          </div>
        </div>
        <div class="choose-seats-foot">
          <div class="choose-seats-fr"></div>
          <div class="choose-seats-f"></div>
          <div class="choose-seats-fl"></div>
        </div>
      </div>
    </div>
    <div class="choose-seats-actives">
      <div class="choose-seats-active" v-if="thisSelected.length">
        <span style="margin-right: 10px">您已选择</span>
        <div class="choose-seats-active-item" v-for="selected in thisSelected" :key="selected">
          <span class="choose-seats-active-text">{{ selected }}</span>
        </div>
        <span style="margin-left: 10px">上车时请对号入座！</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "choose-seats",
  props: {
    selected: {
      default: [],
    },
    seatInfo: {
      required: true,
    },
    max: {
      default: 0,
    },
    kids: {
      default: 0,
    },
    onlyShow: {
      default: false,
    }
  },
  data() {
    return {
      thisSeatInfos: {},
    }
  },
  computed: {
    thisSelected() {
      return this.selected
    },
  },
  watch: {
    thisSelected() {
      this.setSelected()
    }
  },
  methods: {
    onClickSeat(seat) {
      if (seat.sell_status === 1) {
        if (seat.selected) {
          seat.selected = false
          if (this.thisSelected.includes(seat.no)) this.thisSelected.splice(this.thisSelected.indexOf(seat.no), 1)
        } else if (this.thisSelected.length < this.max) {
          seat.selected = true
          if (!this.thisSelected.includes(seat.no)) this.thisSelected.push(seat.no)
          if (seat.type === 1 && this.kids) {
            this.$message.warning('为了您的安全，儿童或携童的乘客，请勿选择副驾驶位置哦！')
          }
        } else {
          this.$message.warning(this.max ? '所有乘客都已选座' : '请先添加乘车人')
          return
        }
        this.thisSelected.sort((a, b) => a - b)
        this.$emit('update:selected', this.thisSelected)
      }
    },
    setSelected() {
      for (const key in this.thisSeatInfos) {
        this.thisSeatInfos[key].selected = this.thisSelected.includes(this.thisSeatInfos[key].no)
      }
    },
  },
  created() {
    this.thisSeatInfos = {}
    this.seatInfo.matrix.forEach(item => {
      this.thisSeatInfos[`${item.x}-${item.y}`] = item
      this.$set(this.thisSeatInfos[`${item.x}-${item.y}`], 'selected', false)
    })
  },
  mounted() {
    this.setSelected()
  }
}
</script>

<style lang="scss">
.choose-seats-app {
  .choose-seats-container {
    overflow: auto;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .choose-seats {
      display: flex;
      align-items: stretch;
      justify-content: center;

      .choose-seats-head, .choose-seats-foot {
        display: flex;
        flex-direction: column;
        width: 35px;
      }

      .choose-seats-foot {
        width: 39px;
      }

      .choose-seats-left, .choose-seats-right {
        display: flex;
        height: 25px;
      }

      .choose-seats-hl, .choose-seats-hr, .choose-seats-fl, .choose-seats-fr {
        height: 60px;
      }

      .choose-seats-lh, .choose-seats-rh {
        width: 50px;
      }

      .choose-seats-h, .choose-seats-f {
        flex-grow: 1;
      }

      .choose-seats-l, .choose-seats-r {
        flex-grow: 1;
      }

      .choose-seats-h {
        background: url("../assets/icon/choose-seats/vehicle-h.png") repeat-y top/100% auto;
      }

      .choose-seats-hl {
        background: url("../assets/icon/choose-seats/vehicle-hl.png") no-repeat center/100%;
      }

      .choose-seats-hr {
        background: url("../assets/icon/choose-seats/vehicle-hr.png") no-repeat center/100%;
      }

      .choose-seats-f {
        background: url("../assets/icon/choose-seats/vehicle-f.png") repeat-y top/100% auto;
      }

      .choose-seats-fl {
        background: url("../assets/icon/choose-seats/vehicle-fl.png") no-repeat center/100%;
      }

      .choose-seats-fr {
        background: url("../assets/icon/choose-seats/vehicle-fr.png") no-repeat center/100%;
      }

      .choose-seats-l {
        background: url("../assets/icon/choose-seats/vehicle-l.png") repeat-x left/auto 100%;
      }

      .choose-seats-lh {
        background: url("../assets/icon/choose-seats/vehicle-lh.png") no-repeat center/100%;
      }

      .choose-seats-r {
        background: url("../assets/icon/choose-seats/vehicle-r.png") repeat-x left/auto 100%;
      }

      .choose-seats-rh {
        background: url("../assets/icon/choose-seats/vehicle-rh.png") no-repeat center/100%;
      }

      .choose-seats-item {
        display: flex;
        flex-direction: column-reverse;

        .choose-seats-item-y {
          display: flex;

          i {
            display: block;
            width: 38px;
            height: 38px;
            margin-left: 15px;
            background-size: 100%;

            &.seat-nosell {
              background-image: url("../assets/icon/choose-seats/seat-nosell.png");
              opacity: 0;
            }

            &.seat-driver {
              background-image: url("../assets/icon/choose-seats/seat-driver.png");
              opacity: 1;
            }

            &.seat-sell {
              background-image: url("../assets/icon/choose-seats/seat-sell.png");
              cursor: pointer;
            }

            &.seat-sold {
              background-image: url("../assets/icon/choose-seats/seat-sold.png");
            }

            &.seat-selected {
              background-image: url("../assets/icon/choose-seats/seat-selected.png");
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .choose-seats-actives {
    height: 38px;

    .choose-seats-active {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $--color-secondary;
      font-size: 12px;

      .choose-seats-active-item {
        height: 38px;
        width: 38px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          display: table;
          content: '';
          height: 100%;
          width: 100%;
          background: url("../assets/icon/choose-seats/seat-sell.png") no-repeat center/100%;
          transform: rotate(90deg);
          position: absolute;
          top: 0;
          left: 0;
        }

        .choose-seats-active-text {
          position: relative;
          color: #ffffff;
          background-color: #F56C6C;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
}
</style>
<template>
  <a href="#" class="scroll-to-top hidden-print"></a>
</template>

<script>
import $ from "jquery";

export default {
  name: "scroll-to-top",
  mounted() {
    //Scroll to Top
    $(".scroll-to-top").click(function () {
      $("html, body").animate({scrollTop: 0}, 600);
      return false;
    });
    // Toggle Scroll to Top button
    $(window).scroll(function () {
      const position = $(window).scrollTop();

      if (position >= 200) {
        $('.scroll-to-top').addClass('active')
      } else {
        $('.scroll-to-top').removeClass('active')
      }
    });
  }
}
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  z-index: 999999;
  display: block;
  right: -100px;
  bottom: 80px;
  width: 45px;
  height: 45px;
  background: url("../../public/images/scroll-to-top.png") no-repeat center center / contain;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
}

.scroll-to-top:hover,
.scroll-to-top:active {
  background: url("../../public/images/scroll-to-top-active.png") no-repeat center center / contain;
  transition: all 0.5s ease;
}

.scroll-to-top.active {
  right: 10px;
}

@media screen and (min-width: 1024px) {
  .scroll-to-top {
    bottom: 45px;
  }
}
</style>
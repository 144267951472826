<template>
  <nav :class="{'sale-model': model}">
    <div class="logo" @click="goHome"><img src="../../public/images/large/logo.png"></div>
    <ul>
      <li :class="active === 'index' ? 'active' : ''" v-if="!model"><a href="/">首页</a></li>
      <li :class="active === 'product' ? 'active' : ''" v-if="!model"><a href="./product.html">产品</a></li>
      <li :class="active === 'news' ? 'active' : ''" v-if="!model"><a href="./news.html">动态</a></li>
      <li :class="active === 'about' ? 'active' : ''" v-if="!model"><a href="./about.html">关于</a></li>
      <li v-if="model">
        <span style="display: flex; align-items: center; color: #ffffff">
          <img class="icon" src="../assets/icon/cs-phone.png">
          <span style="opacity: .6">客服热线：<a href="tel:96579" style="color: inherit">96579</a></span>
        </span>
      </li>
      <li :class="active === 'my' ? 'active' : ''" v-if="!isLogined">
        <a class="my-a" @click="onClickMy"><img class="icon" src="../assets/icon/avatar.png">{{ my.name }}</a>
      </li>
      <li :class="active === 'my' ? 'active' : ''" v-if="isLogined">
        <el-popover placement="bottom-end" trigger="click" visible-arrow="false">
          <template slot="reference">
            <a class="my-a"><img class="icon" src="../assets/icon/avatar.png">{{ my.name }}</a>
          </template>
          <template>
            <ul class="my-submenu">
              <li><a href="./order.html">我的订单</a></li>
              <li><a @click="onClickLogout">退出</a></li>
            </ul>
          </template>
        </el-popover>
      </li>
    </ul>
    <el-dialog title="登录"
               :visible.sync="showLoginDialog"
               width="408px"
               :append-to-body="true"
               :lock-scroll="false"
               :close-on-click-modal="false"
               class="login-dialog"
               @opened="onOpenLoginDialog('form')"
               @closed="onCloseLoginDialog">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item class="phone-item" prop="phone">
          <el-input prefix-icon="phone-icon" placeholder="请输入手机号码" maxlength="11" v-model.trim="form.phone"></el-input>
        </el-form-item>
        <el-form-item class="captcha-item" prop="captcha">
          <el-input prefix-icon="captcha-icon" placeholder="请输入验证码" maxlength="4" v-model.trim="form.captcha">
            <div
                slot="append"
                :disabled="captchaDisabled"
                class="captcha-time"
                :style="{display: !scDisplay ? 'block' : 'none'}">{{ captchaTime }}<span> 秒</span>
            </div>
            <div slot="append" id="sc" :style="{display: scDisplay ? 'block' : 'none'}"></div>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.remember">30 天内自动登录</el-checkbox>
        </el-form-item>
        <el-form-item class="button-item">
          <el-button type="primary" @click="onSubmit('form')">登录</el-button>
        </el-form-item>
      </el-form>
      <div class="text-small">
        <span class="text-placeholder">登录即代表您同意我们的</span>
        <a target="_blank" :href="useProtocol">《软件服务协议》</a>
        <span class="text-placeholder">和</span>
        <a target="_blank" :href="privacyProtocol">《用户隐私政策》</a>
      </div>
    </el-dialog>
  </nav>
</template>

<script>
import cookies from 'js-cookie'
import {loadJs} from "@/utils/async-js";
import {getCaptchaConfig, getCommonPage, login} from "@/api/user-center";
import {sendCaptchaCode} from "@/api/message-center";
import Vue from "vue";
import Element from "element-ui";
// import {getLocation} from "@/api/common";

Vue.use(Element)

export default {
  name: "header-nav",
  props: ['active', 'model', 'triggerLogin'],
  data() {
    return {
      isLogined: false,
      showLoginDialog: false,
      my: {
        name: '我的',
      },
      loginedTo: null,
      form: {
        phone: null,
        captcha: null,
        remember: false,
      },
      rules: {
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {len: 11, message: '手机号码错误', trigger: 'blur'},
          {
            type: 'integer', message: '手机号码错误', trigger: 'blur', transform(value) {
              return Number(value)
            }
          },
        ],
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {len: 4, message: '验证码错误', trigger: 'blur'},
        ],
      },
      useProtocol: '',
      privacyProtocol: '',
      captchaTime: '60',
      captchaDisabled: true,
      scDisplay: true,
      appKey: null,
      scene: null,
      ic: null,
      location: null,
    }
  },
  watch: {
    triggerLogin(newVal) {
      if (newVal) {
        if (this.isLogined) {
          this.$emit('logined')
        } else {
          this.showLoginDialog = true;
        }
      }
    }
  },
  methods: {
    onClickMy() {
      if (!this.isLogined) this.showLoginDialog = true
    },
    onOpenLoginDialog(form) {
      this.$refs[form].resetFields()

      if (!this.ic) {
        this.initCaptcha(form)
      } else {
        this.ic.reset()
      }
    },
    onCloseLoginDialog() {
      if (!this.isLogined) {
        this.$emit('cancelLogin')
      }
    },
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          login({
            type: 1,
            phone: this.form.phone,
            captcha: this.form.captcha,
            code: this.location?.code,
            lon: this.location?.lon,
            lat: this.location?.lat,
          }).then(data => {
            this.my = data[0]
            const arr = data[0].phone.split('')
            arr[3] = arr[4] = arr[5] = arr[6] = '*'
            this.my.name = `欢迎您 ${arr.join('')}`
            sessionStorage.setItem('user-info', JSON.stringify(this.my))
            if (this.form.remember) {
              cookies.set('user-info', JSON.stringify(this.my), {expires: 30})
            }
            this.isLogined = true
            this.captchaTime = 0
            if (this.loginedTo) {
              location.href = this.loginedTo
            } else {
              this.showLoginDialog = false
              this.$emit('logined')
            }
          }).catch(e => {
            console.error(e.message)
            this.$message.error(e.message)
          })
        } else {
          return false;
        }
      });
    },
    onClickLogout() {
      sessionStorage.removeItem('user-info')
      cookies.remove('user-info')
      this.isLogined = false
      this.my = {
        name: '我的',
      }
      this.$emit('logouted')
    },
    goHome() {
      location.href = '../'
    },
    initCaptcha(form) {
      loadJs('https://g.alicdn.com/AWSC/AWSC/awsc.js').then(() => {
        getCaptchaConfig().then(data => {
          const appKey = data[0].appkey
          const scene = data[0].scene
          /* eslint-disable */
          AWSC.use("ic", (state, module) => {
            /* eslint-enable */
            this.ic = module.init({
              appkey: appKey,
              scene: scene,
              renderTo: 'sc',
              width: '112px',
              height: '38px',
              default_txt: '获取验证码',
              // scanning_txt: '智能验证中...',
              success_txt: '验证码发送中...',
              fail_txt: '检测失败，请重试',
              // test: module.TEST_NC_PASS,
              success: (data) => {
                this.$refs[form].validateField('phone', msg => {
                  if (!msg) {
                    sendCaptchaCode({
                      phone: this.form.phone,
                      appKey: appKey,
                      scene: scene,
                      sign: data.sig,
                      sessionId: data.sessionId,
                      token: data.token,
                    }).then(() => {
                      this.$message.success(`验证码已发送，请注意查收`)
                      this.captchaTimer()
                    }).catch(() => {
                      this.$message.error(`验证码发送失败，请稍后再试`)
                      this.ic.reset()
                    });
                  } else {
                    this.ic.reset()
                  }
                })
              },
              fail: (failCode) => {
                console.error(failCode)
              },
              error: (errorCode) => {
                console.error(errorCode)
                this.$message.error('网络异常，请稍后再试')
                this.ic.reset()
              }
            });
          });
        })
      })
    },
    captchaTimer() {
      if (this.captchaTime > 0) {
        this.scDisplay = false
        localStorage.setItem('captcha-time', this.captchaTime)
        setTimeout(() => {
          this.captchaTime -= 1
          this.captchaTimer()
        }, 1000)
      } else {
        this.captchaTime = 60
        this.ic?.reset()
        localStorage.removeItem('captcha-time')
        this.scDisplay = true
      }
    },
  },
  created() {
    const captchaTime = Number(localStorage.getItem('captcha-time'))
    if (captchaTime) {
      this.captchaTime = captchaTime
      this.captchaTimer()
    }

    getCommonPage().then(data => {
      this.useProtocol = data[0].tfxing_use_protocol
      this.privacyProtocol = data[0].tfxing_privacy_protocol
    }).catch(e => {
      this.$message.error(e.message)
    })

    // navigator.geolocation.getCurrentPosition(position => {
    //   console.log(position)
    // })

    loadJs('https://pv.sohu.com/cityjson?ie=utf-8').then(() => {
      this.location = {
        /* eslint-disable */
        ip: returnCitySN.cip,
        code: returnCitySN.cid,
        name: returnCitySN.cname,
        /* eslint-enable */
      }
      // 高德不再提供此接口
      // getLocation(this.location.ip).then(data => {
      //   this.location.lon = data.location.split(',')[0]
      //   this.location.lat = data.location.split(',')[1]
      // })
    }).catch(() => console.error('获取IP异常'))

    let user = JSON.parse(sessionStorage.getItem('user-info'))
    if (user) {
      this.my = user
      this.isLogined = true
    } else {
      user = JSON.parse(cookies.get('user-info') ? cookies.get('user-info') : null)
      if (user) {
        login({
          type: 2,
          phone: user.phone,
          password: user.password,
          code: this.location?.code,
          lon: this.location?.lon,
          lat: this.location?.lat,
        }).then(data => {
          this.my = data[0]
          const arr = data[0].phone.split('')
          arr[3] = arr[4] = arr[5] = arr[6] = '*'
          this.my.name = `欢迎您 ${arr.join('')}`
          sessionStorage.setItem('user-info', JSON.stringify(this.my))
          this.isLogined = true
          this.captchaTime = 0
        }).catch(e => {
          console.error(e.message)
          this.$message.error('登录信息失效，您需要重新登录')
        })
      }
    }
  },
}
</script>

<style lang="scss">
header.index > nav {
  &.sale-model {
    background: rgba(0, 8, 88);
  }

  img.icon {
    height: 32px;
  }
}

.my-a {
  display: flex;
  align-items: center;
  margin-left: -0.2rem;
  color: #ffffff;
  cursor: pointer;

  &:hover, &:active {
    color: #ffffff;
  }
}

.my-submenu {
  line-height: 2;

  li {
    padding-left: 10px;
    display: flex;
    cursor: pointer;

    a {
      flex-grow: 1;
    }

    &:hover {
      background-color: #f7f7ff;
    }
  }
}

.login-dialog {
  .el-input__prefix {
    .el-input__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        display: table;
        content: "";
        width: 12px;
        height: 14px;
        background-size: 100%;
      }
    }

    .phone-icon::before {
      background-image: url("../assets/icon/phone-input.png");
    }

    .captcha-icon::before {
      background-image: url("../assets/icon/captcha-input.png");
    }
  }

  .captcha-item {
    .el-input-group__append {
      width: 200px;
    }

    .captcha-time {
      cursor: pointer;
      text-align: center;
      margin: 0 -20px;
      line-height: 38px;
    }
  }

  #sc {
    margin: 0 -20px !important;

    .sm-btn {
      line-height: normal;
      border: none;
      cursor: pointer;
      overflow: hidden;
      height: inherit;

      .sm-ico {
        margin-top: 0;
        height: 38px;
      }

      .sm-txt {
        margin-left: 10px;
      }
    }

    .nc_wrapper {
      width: 198px;
    }

    .sm-pop {
      top: 1px;
    }
  }

  .button-item {
    .el-form-item__content {
      display: flex;
      justify-content: center;

      button {
        width: 90%;
      }
    }
  }
}
</style>
import {send} from "@/api/common";

const path = `/messageCenter`

async function sendCaptchaCode(params) {
    const req = {
        "optid": "send_verification_code",
        "terminal": "1",
        "version": "2",
        "common_param": {
            "page_index": "0",
            "page_size": "0",
            "is_paging": "0",
            "is_couting": "0"
        },
        "business_param": {
            "phone": params.phone,   //手机号码(必填)
            "session_id": params.sessionId,    //回话id(必填)
            "sign": params.sign,    //签名串(必填)
            "token": params.token,    //令牌(必填)
            "scene": params.scene,    //场景(必填)
            "appkey": params.appKey,    //appkey(必填)
            "platform": null    //平台标识(非必填)
        }
    }
    return send(path, req)
}

export {sendCaptchaCode}